<template>
   <el-main >
       <el-row style="overflow: hidden">
           <el-col :offset="2" :span="20">
               <el-container class="main-container">
                   <el-header>
                       <el-menu :default-active="$route.path"  mode="horizontal" router>
                           <el-menu-item index="/code-generate">条码生成</el-menu-item>
                           <el-menu-item index="/json-formatter">JSON格式化</el-menu-item>
                           <el-menu-item index="/string-enc-dec">字符串编解码</el-menu-item>
                           <el-menu-item index="/ip-utils">IP工具</el-menu-item>
                           <el-menu-item index="/docker-utils">Docker工具</el-menu-item>

                           <el-menu-item index="/websocket">WebSocket</el-menu-item>
                           <el-menu-item index="/baidu-map">百度地图</el-menu-item>
                           <el-menu-item index="/netdisk-parse">网盘解析</el-menu-item>
<!--                           <el-menu-item index="/video-parse">视频解析</el-menu-item>-->
                           <el-menu-item index="/color-conversion">颜色转换</el-menu-item>
                           <el-menu-item index="/unit-conversion">单位转换</el-menu-item>
<!--                           <el-menu-item index="/ocr">OCR</el-menu-item>-->
<!--                           <el-menu-item index="/bpmn">流程设计器</el-menu-item>-->
                           <el-menu-item index="/label-designer">标签设计器</el-menu-item>
                           <el-menu-item index="/ai-translate">AI翻译</el-menu-item>
<!--                           <el-menu-item index="/proxy-detect">代理检测</el-menu-item>-->
                       </el-menu>
                   </el-header>
                   <el-main style="height: 87vh">
                       <keep-alive>
                           <router-view></router-view>
                       </keep-alive>
                   </el-main>
                   <el-footer style="height: 40px;display: flex;justify-content: center">
                       <el-link href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">湘ICP备20006615号-1</el-link>
                   </el-footer>
               </el-container>
           </el-col>
       </el-row>
   </el-main>
</template>
<script>
    export default {
        name: "Home",
        data() {
            return {

            }
        },
        mounted(){

        },

    }
</script>

<style  scoped>
    /*.main-container {
        height: 100%;
        overflow: hidden;
    }*/

    a {text-decoration: none}




    /* 表单校验样式 */

</style>
