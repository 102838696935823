<template>
    <div>
        <el-card class="box-card">
            <el-form label-width="70px" @submit.native.prevent size="small">
                <el-form-item label="内容">
                    <el-input
                        type="textarea"
                        :rows="14"
                        placeholder="请输入内容"
                        v-model="formModel.content" resize="none" show-word-limit>
                    </el-input>
                </el-form-item>
                <el-form-item>

                    <el-select v-model="formModel.sourceLang" placeholder="选择源语言"
                               style="width: 80px;margin-right: 12px">
                        <el-option
                            v-for="lang in languages"
                            :key="lang.value"
                            :label="lang.label"
                            :value="lang.value"
                        />
                    </el-select>
                    <i class="el-icon-arrow-right"/>

                    <el-select v-model="formModel.targetLang" placeholder="选择目标语言"
                               style="width: 80px;margin-left: 12px;margin-right: 12px">
                        <el-option
                            v-for="lang in languages"
                            :key="lang.value"
                            :label="lang.label"
                            :value="lang.value"
                        />
                    </el-select>
                    <el-button @click="handleTranslation" type="primary" :loading="loading">翻译</el-button>

                    <el-button @click="handleClear" type="danger">清空</el-button>

                </el-form-item>

            </el-form>
        </el-card>
        <div style="height: 10px"></div>
        <el-card class="box-card code-container" :body-style="{ paddingBottom: '0px' }">
            <el-form label-width="70px" @submit.native.prevent size="small">

                <el-form-item label="结果">
                    <el-input
                        type="textarea"
                        :rows="14"
                        placeholder="结果"
                        v-model="formModel.result" resize="none" show-word-limit>
                    </el-input>
                </el-form-item>
            </el-form>

        </el-card>


    </div>
</template>

<script>

export default {
    name: "AiTranslation",
    components: {},
    data() {
        return {
            formModel: {
                sourceLang: 'en',
                targetLang: 'zh',
                content: 'hello world',
            },


            loading: false,
            languages: [
                {value: 'zh', label: '中文'},
                {value: 'en', label: '英语'},
                {value: 'ja', label: '日语'},
                {value: 'ko', label: '韩语'},
                {value: 'fr', label: '法语'},
            ],

        }
    },
    computed: {},
    methods: {
        async handleTranslation() {
            let {content, sourceLang, targetLang} = this.formModel
            if (this.$strings.isBlank(this.formModel.content)) {
                this.$message.warning('请输入要翻译的内容');
                return;
            }

            this.loading = true;

            /*    prompt = f"Please identify the language of the following text: \"{text}\". If the language is unknown, say 'unknown'." */

            //const apiUrl = 'https://tools.yuntao.xyz/api/openai/chat/completions';
            const apiUrl = '/openai/chat/completions';


            const data = {
                model: 'gpt-4o-mini', // 或者使用其他支持的模型
                messages: [
                    {
                        role: 'system',
                        content: `你是一个专业的翻译引擎.请将以下 ${this.getFullLangName(sourceLang)} 内容翻译成 ${this.getFullLangName(targetLang)}，只需返回翻译结果，不要添加任何解释`,
                    },
                    {
                        role: 'user',
                        content: `${content}`,
                    },

                ],
                //max_tokens: 100,
                temperature: 0.7,
            };

            this.$http.post(apiUrl, data)
                .then((response) => {
                    const translatedText = response.choices[0].message.content;
                    this.$set(this.formModel, 'result', translatedText)
                })
                .catch((error) => {
                    this.$message.error('翻译失败，请稍后重试');

                    // console.error('Error:', error.response ? error.response.data : error.message);
                }).finally(() => {
                this.loading = false
            });
        },

        getFullLangName(code) {
            const lang = this.languages.find(l => l.value === code);
            return lang ? lang.label : code;
        },

        handleClear() {
            this.$set(this.formModel, 'content', '')
            this.$set(this.formModel, 'result', '')
        }
    },
    created() {

    }
}
</script>

<style scoped>

.code-container {
    width: 100%;
    /*height: 40vh;*/
    overflow-y: auto;
}

.code-wrapper {
    display: inline-block;
    margin-right: 10px
}

.el-radio {
    margin-right: 10px;
}
</style>
